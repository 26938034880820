import React from 'react';
import { connect } from 'react-redux';

import { getFullName } from 'helpers/persons';

import { DETAILS_ENTITY_TYPE, OFFER_TYPE_OPTIONS } from '../../../app.constants';

const loadingString = '...';

const defaultComponentRender = props => {
  const doc =
    props.records[props.id] ||
    (props.singleRecords && props.singleRecords[props.id]) ||
    {};

  const valueToShow = [
    doc.administrative_title,
    doc.name && doc.name.first_name && getFullName(doc.name),
    doc.name,
    doc.key,
    doc.first_name ? `${doc.first_name} ${doc.last_name}` : doc.last_name,
    doc.label,
    doc.data && doc.data.name,
    (doc.source && doc.source.name) || doc.source_name,
    doc.id,
    loadingString,
  ].find(Boolean);

  return <span>{valueToShow}</span>;
};

function deepGet(path, object) {
  return path.split('.').reduce((o, k) => o[k], object);
}

const Component = connect((state, props) =>
  deepGet(props.fromData ? `data.${props.entity}` : props.entity, state)
)(props => {
  return props.renderCb ? props.renderCb(props) : defaultComponentRender(props);
});

const planRender = props => {
  const offer = props.records[props.id];

  if (!offer) {
    return loadingString;
  }

  const plan = offer.factors.plans[props.planIndex];

  return <span>{(plan && plan.name) || '(plan not found)'}</span>;
};

const getById =
  (entity, fromData = true) =>
  ({ match }) =>
    (
      <Component
        fromData={fromData}
        entity={entity}
        id={match.params.id}
        renderCb={defaultComponentRender}
      />
    );

const FromRecord = connect((state, props) => deepGet(props.storePath, state))(
  props =>
    (props.records[props.id] && deepGet(props.labelPath, props.records[props.id])) ||
    '...'
);

const getFromRecord =
  (storePath, labelPath) =>
  ({ match }) =>
    <FromRecord storePath={storePath} labelPath={labelPath} id={match.params.id} />;

const getBySection =
  sectionNames =>
  ({ match }) =>
    <span>{sectionNames[match.params.section] || '(n/a)'}</span>;

const getBreadcrumbByType =
  types =>
  ({ match }) =>
    <span>{types[match.params.type] || '(n/a)'}</span>;

const getByLastId = ({ match }) =>
  match.params.path ? (
    <Component
      fromData
      entity="customFields"
      id={match.params.path.split('/').splice(-1)[0]}
      renderCb={defaultComponentRender}
    />
  ) : (
    'Custom Fields'
  );

const getPlanName = ({ match }) => (
  <Component
    fromData
    entity="offers"
    id={match.params.id}
    renderCb={planRender}
    planIndex={match.params.planIndex}
  />
);

const getByType = ({ match }) =>
  `New ${OFFER_TYPE_OPTIONS.find(([key]) => key === match.params.type)[1]} Offer`;

const companySections = {
  'custom-fields': 'Custom Fields',
  'signup-fields': 'Signup Fields',
  'activation-fields': 'Activation Fields',
  'add-ons': 'Add-Ons',
  documents: 'Documents',
  templates: 'Templates',
  'distribution-channels': 'Distribution Channels',
  affiliate: 'Affiliate',
  'crypt-delegations': 'Crypt Delegations',
  authorizations: 'Authorizations',
  triggers: 'Triggers',
  api: 'API',
  history: 'History',
};

const documentSections = {
  'api-meta': 'API (metadata)',
  'api-view': 'API (view)',
};

const emailSections = {
  preview: 'Preview',
  replies: 'Replies',
  api: 'API',
};

const smsSections = {
  preview: 'Preview',
  api: 'API',
};

const activationSections = {
  contacts: 'Communication',
  history: 'History',
  admin: 'Admin',
  api: 'API',
};

const offerSections = {
  plans: 'Plans',
  pricing: 'Pricing',
  companies: 'Companies',
  resellers: 'Resellers',
  documents: 'Documents',
  addons: 'Add-Ons',
  'custom-fields': 'Custom Fields',
  'distribution-channels': 'Distribution Channels',
  history: 'History',
  api: 'API',
};

const userSections = {
  security: 'Security',
  assignments: 'Assignments',
  roles: 'Roles',
  permissions: 'Permissions',
  api: 'API',
};

const profileSections = {
  permissions: 'Permissions',
  security: 'Security',
  activations: 'Activations',
  people: 'People',
  assignments: 'Assignments',
  triggers: 'Triggers',
  dealer: 'Dealer',
};

const peopleSections = {
  contact: 'Contact',
  activations: 'Activations',
  analytics: 'Analytics',
  import: 'Import',
  history: 'History',
  api: 'API',
  communication: 'Communication',
  webhooks: 'Webhooks',
};

const personSections = {
  leases: 'Leases',
  schema: 'Schema',
  details: 'Details',
  import: 'Import',
  webhooks: 'Webhooks',
};

const emailTemplateSections = {
  readme: 'README',
  preview: 'Preview',
  templates: 'Templates',
  media: 'Media',
  vars: 'Variables',
  'default-headers': 'Default headers',
  api: 'API',
};

const importSections = {
  records: 'Records',
  log: 'Log',
  api: 'API',
};

const importSourceSections = {
  properties: 'Properties',
  imports: 'Imports',
  api: 'API',
};

const cryptKeySections = {
  data: 'Data',
  api: 'API',
};

const cryptDataSections = {
  api: 'API',
};

const triggerSections = {
  global: 'Global',
  'schema-registry': 'Schema Registry',
  'triggers-history': 'Triggers History',
};

const dealerSections = {
  api: 'API',
  companies: 'Companies',
  triggers: 'Triggers',
  'custom-fields': 'Custom Fields',
  'distribution-channels': 'Distribution Channels',
  authorizations: 'Authorizations',
};

const placeListSections = {
  primaryPlace: 'Primary Place',
  places: 'Places',
  schema: 'Schema',
  details: 'Details',
  classification: 'Classification',
  import: 'Import',
  webhooks: 'Webhooks',
  api: 'API',
  groups: 'Groups',
};

const placeSections = {
  schema: 'Schema',
  details: 'Details',
  properties: 'Properties',
  products: 'Products',
  webhooks: 'Webhooks',
  import: 'Import',
};

const fixerTypes = {
  [DETAILS_ENTITY_TYPE.place]: 'Validate place',
  [DETAILS_ENTITY_TYPE.person]: 'Validate person',
  [DETAILS_ENTITY_TYPE.placeList]: 'Validate place list',
};

export default [
  { path: '/offers/new/:type', breadcrumb: getByType },
  { path: '/offers/show/:id', breadcrumb: getById('offers') },
  { path: '/offers/show/:id/:section', breadcrumb: getBySection(offerSections) },
  { path: '/offers/show/:id/plans/:planIndex', breadcrumb: getPlanName },
  { path: '/offers', breadcrumb: 'Offers' },
  { path: '/companies/new', breadcrumb: 'New Company' },
  { path: '/documents', breadcrumb: 'Documents' },
  { path: '/documents/show/:id', breadcrumb: getById('documents') },
  { path: '/documents/show/:id/:section', breadcrumb: getBySection(documentSections) },
  { path: '/companies', breadcrumb: 'Companies' },
  { path: '/companies/show/:id', breadcrumb: getById('companies') },
  { path: '/companies/show/:id/:section', breadcrumb: getBySection(companySections) },
  { path: '/offers/distribution-channels', breadcrumb: 'Distribution Channels' },
  {
    path: '/companies/show/:companyId/distribution-channels/:id',
    breadcrumb: getById('distributionChannels', false),
  },
  { path: '/companies/show/:companyId/triggers/new', breadcrumb: 'New' },
  { path: '/companies/show/:companyId/triggers/:id', breadcrumb: 'Edit' },
  { path: '/contact/email-templates', breadcrumb: 'Email Templates' },
  { path: '/contact/email-templates/new', breadcrumb: 'New' },
  { path: '/contact/email-templates/preview/:id', breadcrumb: getById('emailTemplates') },
  { path: '/contact/email-templates/edit/:id', breadcrumb: getById('emailTemplates') },
  { path: '/contact/email-templates/show/:id', breadcrumb: getById('emailTemplates') },
  {
    path: '/contact/email-templates/show/:id/:section',
    breadcrumb: getBySection(emailTemplateSections),
  },
  { path: '/contact/emails', breadcrumb: 'Emails' },
  { path: '/contact/emails/send', breadcrumb: 'Send' },
  { path: '/contact/emails/show/:id', breadcrumb: getById('emails') },
  { path: '/contact/emails/show/:id/:section', breadcrumb: getBySection(emailSections) },
  { path: '/contact/sms', breadcrumb: 'SMS' },
  { path: '/contact/sms/send', breadcrumb: 'Send' },
  { path: '/contact/sms/show/:id', breadcrumb: getById('sms') },
  { path: '/contact/sms/show/:id/:section', breadcrumb: getBySection(smsSections) },
  { path: '/contact/send-settings/sms', breadcrumb: 'SMS Send Settings' },
  { path: '/contact/send-settings/sms/new', breadcrumb: 'New' },
  { path: '/activations', breadcrumb: 'Activations' },
  { path: '/activations/show/:id', breadcrumb: getById('activations') },
  {
    path: '/activations/show/:id/:section',
    breadcrumb: getBySection(activationSections),
  },
  { path: '/offers/recommendations', breadcrumb: 'Recommendations' },
  { path: '/custom-fields/show/:path*', exact: false, breadcrumb: getByLastId },
  { path: '/activations/affiliates', breadcrumb: 'Affiliates' },
  { path: '/activations/affiliates/fees', breadcrumb: 'Fees' },
  { path: '/activations/affiliates/statements', breadcrumb: 'Statements' },
  { path: '/profile', breadcrumb: 'Profile' },
  { path: '/profile/:section', breadcrumb: getBySection(profileSections) },
  { path: '/profile/triggers/new', breadcrumb: 'New Trigger' },
  { path: '/profile/triggers/:id', breadcrumb: 'Edit' },
  { path: '/users/show', breadcrumb: 'Users' },
  { path: '/users/show/new', breadcrumb: 'New User' },
  { path: '/users/show/:id', breadcrumb: getById('users', false) },
  { path: '/users/show/:id/:section', breadcrumb: getBySection(userSections) },
  { path: '/persons/', breadcrumb: 'People' },
  { path: '/leases', breadcrumb: 'Person Leases' },
  { path: '/persons/show/:id', breadcrumb: getById('persons', false) },
  { path: '/persons/show/:id/:section', breadcrumb: getBySection(personSections) },
  { path: '/people/', breadcrumb: 'People' },
  { path: '/people/show/:id', breadcrumb: getById('people', true) },
  { path: '/people/show/:id/:section', breadcrumb: getBySection(peopleSections) },
  { path: '/import', breadcrumb: 'Import' },
  { path: '/import/imports', breadcrumb: 'Imports' },
  { path: '/import/imports/show/:id', breadcrumb: getById('import.imports', false) },
  { path: '/import/imports/spreadsheet', breadcrumb: 'Spreadsheet' },
  { path: '/import/imports/new', breadcrumb: 'New Import' },
  { path: '/import/imports/show/:id/:section', breadcrumb: getBySection(importSections) },
  { path: '/import/sources', breadcrumb: 'Sources' },
  { path: '/import/records', breadcrumb: 'Import Records' },
  {
    path: '/import/sources/show/:id',
    breadcrumb: getFromRecord('import.sources', 'name'),
  },
  {
    path: '/import/sources/show/:id/:section',
    breadcrumb: getBySection(importSourceSections),
  },
  { path: '/import/jobs', breadcrumb: 'Jobs' },
  { path: '/url-shorts', breadcrumb: 'Url Shorts' },
  { path: '/crypt', breadcrumb: 'Crypt' },
  { path: '/crypt/keys', breadcrumb: 'Keys' },
  { path: '/crypt/keys/new', breadcrumb: 'New' },
  { path: '/crypt/keys/show/:id', breadcrumb: getById('crypt.keys', false) },
  { path: '/crypt/keys/show/:id/:section', breadcrumb: getBySection(cryptKeySections) },
  { path: '/crypt/data', breadcrumb: 'Data' },
  { path: '/crypt/data/new', breadcrumb: 'New Crypt Data' },
  { path: '/crypt/data/show/:id', breadcrumb: getById('crypt.data', false) },
  { path: '/crypt/data/show/:id/:section', breadcrumb: getBySection(cryptDataSections) },
  { path: '/triggers', breadcrumb: 'Triggers' },
  { path: '/triggers/:section', breadcrumb: getBySection(triggerSections) },
  { path: '/triggers/:section/new', breadcrumb: 'New' },
  { path: '/triggers/:section/:id', breadcrumb: 'Edit' },
  { path: '/dealers', breadcrumb: 'Dealers' },
  { path: '/dealers/new', breadcrumb: 'New' },
  { path: '/dealers/:id', breadcrumb: getById('dealers.dealers', false) },
  { path: '/dealers/:id/:section', breadcrumb: getBySection(dealerSections) },
  {
    path: '/dealers/:dealerId/distribution-channels/:id',
    breadcrumb: getById('distributionChannels', false),
  },
  { path: '/zipcodegroups', breadcrumb: 'Zipcode Groups' },
  { path: '/zipcodegroups/new', breadcrumb: 'New' },
  { path: '/zipcodegroups/edit/:id', breadcrumb: getById('zipcodegroups', false) },
  { path: '/properties', breadcrumb: 'Properties' },
  {
    path: '/properties/show/:id',
    breadcrumb: getById('placeList.places', false),
  },
  { path: '/properties/show/:id/:section', breadcrumb: getBySection(placeSections) },
  { path: '/place-list', breadcrumb: 'Place list' },
  { path: '/place-list/show/:id', breadcrumb: getById('placeList', false) },
  { path: '/place-list/show/:id/:section', breadcrumb: getBySection(placeListSections) },
  { path: '/invalidator', breadcrumb: 'Details Fixer' },
  { path: '/invalidator/:type/:id', breadcrumb: getBreadcrumbByType(fixerTypes) },
];
